$c-primary: #2c6fc8;
$c-secandary: #2c6fc8;
$dots: false;
$version: 3.1;
$body-color-overrided: #54565a;
$loading-bg-overrided: #ffffff;
$progress-bar-overided: #2c6fc8;
$dot-overrided: #2c6fc8;
$base-font-family: 'HelveticaNeue', Ariel, sans-serif;
$font-family-heading: 'HelveticaNeue', Ariel, sans-serif;

      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-RegularItalic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formContainer {
  margin-top: 24px;
}

.formInputRow {
  position: relative;
  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 30%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}
