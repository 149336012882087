$c-primary: #2c6fc8;
$c-secandary: #2c6fc8;
$dots: false;
$version: 3.1;
$body-color-overrided: #54565a;
$loading-bg-overrided: #ffffff;
$progress-bar-overided: #2c6fc8;
$dot-overrided: #2c6fc8;
$base-font-family: 'HelveticaNeue', Ariel, sans-serif;
$font-family-heading: 'HelveticaNeue', Ariel, sans-serif;

      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-RegularItalic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding: 0 25px 0;

  h3 {
    text-align: center;
    font-family: var(--base-font-family) !important;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 16px;
  }

  button {
    max-width: 265px;
  }

  .swContainer {
    width: 100%;
    max-height: 225px;
    max-width: 320px;
    margin-top: 20px;
    //margin-bottom: 10px;

    img {
      display: inline-block;
      margin: auto;
      height: 100%;
      vertical-align: bottom;
    }
  }

  .dots {
    margin: 14px 0 0;
    padding: 0;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #f2f2f2;
      margin: 0 5px;

      &.active {
        background: var(--primary);
      }
    }
  }
}
