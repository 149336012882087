$c-primary: #2c6fc8;
$c-secandary: #2c6fc8;
$dots: false;
$version: 3.1;
$body-color-overrided: #54565a;
$loading-bg-overrided: #ffffff;
$progress-bar-overided: #2c6fc8;
$dot-overrided: #2c6fc8;
$base-font-family: 'HelveticaNeue', Ariel, sans-serif;
$font-family-heading: 'HelveticaNeue', Ariel, sans-serif;

      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-RegularItalic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/fonts/HelveticaNeue-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  padding-left: 25px;

  label {
    display: block;
    position: relative;
    align-items: center;
    min-height: 28px;
    padding-left: 7px;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary);
  }

  label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin-left: -25px;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  label::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 11px;
    margin-left: -25px;
    top: 5px;
    left: 6px;
    margin-top: -3px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none !important;
    transform: rotate(45deg);
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + label::before {
    border: 1px solid var(--secondary);
  }
  input:checked + label::before,
  input:checked + label::after {
    background: var(--secondary);
    display: block;
  }
}
